import "./App.css";
import { useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

function App() {
  const navRef = useRef(null);
  const textElements = useRef([]);

  useEffect(() => {
    const nav = navRef.current;
    const elements = textElements.current;

    const checkCollision = () => {
      const navRect = nav.getBoundingClientRect();
      elements.forEach((element) => {
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top < navRect.bottom && rect.bottom > navRect.top) {
            element.classList.add("inverted");
          } else {
            element.classList.remove("inverted");
          }
        }
      });
    };

    window.addEventListener("scroll", checkCollision);
    checkCollision();
    return () => window.removeEventListener("scroll", checkCollision);
  }, []);

  const projects = [
    { no: "001", client: "SnooSell", type: "AI powered extension for resellers.", year: "2025" },
    { no: "002", client: "HatchNYU", type: "NYU's Playground for Big Ideas.", year: "2025" },
    { no: "003", client: "RedditSim", type: "AI powered reddit simulator. Search any topic you can imagine.", year: "2024" },
    { no: "004", client: "ConvinceGG", type: "AI powered web game.", year: "2024" },
    { no: "005", client: "Dragon AI", type: "Tutor for kids, powered by AI.", year: "2024" },
    { no: "006", client: "Yabnee.com", type: "Raise venture capital in the MENA region.", year: "2024" },
    { no: "007", client: "Arete Publishing", type: "Publishing platform for bloggers.", year: "2024" },
  ];

  // Add touch event handling
  const handleTouchStart = (e) => {
    e.currentTarget.classList.add("touched");
  };

  const handleTouchEnd = (e) => {
    e.currentTarget.classList.remove("touched");
  };

  return (
    <div className="App">
      <motion.nav
        className="navbar"
        ref={navRef}
        initial={false}
        animate={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.4,
          ease: "easeOut",
        }}
      >
        <div className="nav-content">
          <a
            href="/"
            className="logo"
            ref={(el) => textElements.current.push(el)}
          >
            <img src="/logo.png" alt="Logo" className="logo-image" />
            two<span className="logo-separator">/</span>cats
            <span className="logo-cursor">_</span>
          </a>
          <div className="nav-links">
            <a
              href="#work"
              className="nav-link"
              ref={(el) => textElements.current.push(el)}
            >
              Work
            </a>
            <a
              href="#contact"
              className="nav-link"
              ref={(el) => textElements.current.push(el)}
            >
              Contact
            </a>
          </div>
        </div>
      </motion.nav>

      <motion.section
        className="hero"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.6,
          ease: "easeOut",
        }}
      >
        <div className="hero-content">
          {/* <div className="hackathon-tag">Incubation Lab</div> */}
          <h1 className="hero-title">
            <div className="hero-line">2cats</div>
            <div className="hero-line">
              -lab<span className="trademark">™</span>
            </div>
          </h1>
          <div className="hero-footer">
            <p className="hero-description">
              An incubation lab running like a perpetual hackathon. We build and
              scale in-house startups.
            </p>
          </div>
        </div>
      </motion.section>

      {/* <motion.section
        className="approach-section"
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <h2 className="section-title">
          Nine Lives
          <span className="artistic-note">
            // where curiosity meets creativity
          </span>
        </h2>
        <div className="lives-container">
          <div className="life-item">
            <div className="life-marker">
              <span className="life-number">01</span>
              <div className="life-line"></div>
            </div>
            <div className="life-content">
              <h3>Curiosity</h3>
              <p>Always exploring new territories in tech</p>
              <div className="life-tags">
                <span className="life-tag">Explorer</span>
                <span className="life-tag">Seeker</span>
              </div>
            </div>
          </div>

          <div className="life-item">
            <div className="life-marker">
              <span className="life-number">02</span>
              <div className="life-line"></div>
            </div>
            <div className="life-content">
              <h3>Agility</h3>
              <p>Quick to adapt and pivot when needed</p>
              <div className="life-tags">
                <span className="life-tag">Swift</span>
                <span className="life-tag">Flexible</span>
              </div>
            </div>
          </div>

          <div className="life-item">
            <div className="life-marker">
              <span className="life-number">03</span>
              <div className="life-line"></div>
            </div>
            <div className="life-content">
              <h3>Precision</h3>
              <p>Calculated execution in every project</p>
              <div className="life-tags">
                <span className="life-tag">Focused</span>
                <span className="life-tag">Sharp</span>
              </div>
            </div>
          </div>
        </div>
        <div className="artistic-elements">
          <div className="circle-element"></div>
          <div className="line-element"></div>
          <div className="dot-pattern"></div>
        </div>
      </motion.section> */}

      <section id="work" className="work-section">
        <div className="work-header">
          <h2 className="section-title">In-House Built Startups</h2>
          <div className="work-year">2024—Present</div>
        </div>
        <div className="work-table">
          <div className="table-header">
            <div className="col-no">No.</div>
            <div className="col-client">Company</div>
            <div className="col-type">desc</div>
            <div className="col-year">Founded</div>
          </div>
          {projects.map((project) => (
            <div
              className="table-row"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className="col-no">{project.no}</div>
              <div className="col-client">{project.client}</div>
              <div className="col-type">{project.type}</div>
              <div className="col-year">{project.year}</div>
            </div>
          ))}
        </div>
      </section>

      <section id="process" className="process-section">
        <h2 className="section-title">How We Hunt</h2>
        <div className="process-steps">
          <div className="process-step">
            <div className="step-number">01</div>
            <h3>Prowl</h3>
            <p>Identify opportunities in the market</p>
          </div>
          <div className="process-step">
            <div className="step-number">02</div>
            <h3>Pounce</h3>
            <p>Rapid prototype and validation</p>
          </div>
          <div className="process-step">
            <div className="step-number">03</div>
            <h3>Perfect</h3>
            <p>Iterate and scale with precision</p>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <a href="/" className="footer-logo">
              two<span className="logo-separator">/</span>cats
            </a>
            <p className="footer-tagline">
              Building the future, one life at a time.
            </p>
          </div>

          <div className="footer-right">
            <div className="footer-links">
              <div className="footer-column">
                <h4>Navigate</h4>
                <a href="#work">Work</a>
                <a href="#approach">Approach</a>
                <a href="#process">Process</a>
              </div>
              <div className="footer-column">
                <h4>Connect</h4>
                <a href="mailto:hello@twocats.com">Email</a>
                <a
                  href="https://twitter.com/twocats"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
                <a
                  href="https://github.com/twocats"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-copyright">
            © {new Date().getFullYear()} Two Cats. All rights reserved.
          </div>
          <div className="footer-location">
            Made with <span className="accent-text">curiosity</span> in NYC
          </div>
        </div>
      </footer>

      <motion.div className="background-effects">
        <motion.div
          className="floating-shape shape-1"
          animate={{
            y: [0, -30, 0],
            rotate: [0, 360],
            scale: [1, 1.1, 1],
            borderRadius: [
              "40% 60% 70% 30%",
              "60% 40% 30% 70%",
              "40% 60% 70% 30%",
            ],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear",
          }}
        />
        <motion.div
          className="floating-dots"
          animate={{
            rotate: [0, 360],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 30,
            repeat: Infinity,
            ease: "linear",
          }}
        />
        <motion.div
          className="particle-trail"
          animate={{
            pathLength: [0, 1],
            opacity: [0.2, 0.4, 0.2],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      </motion.div>
    </div>
  );
}

export default App;
